//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ButtonRouter from '@/shared/components/ButtonRouter.vue';
import FightCampTrackersWBag from '@/shared/datasets/products/fightcamp_trackers_with_bag';
import { AffirmLogo } from '@fc/angie-ui';

export default {
  name: 'Financing_CallToAction',
  components: {
    ButtonRouter,
    AffirmLogo,
  },
  computed: {
    package() {
      return {
        months: FightCampTrackersWBag.months,
        name: `${FightCampTrackersWBag.title} Package`,
        fullPrice: this.$options.filters.currency2(FightCampTrackersWBag.price),
      };
    },
    cta() {
      return { text: 'Shop Now', link: '/shop' };
    },
    subPrice() {
      if (this.$store.getters.isExp_035_subPriceVariant) {
        return '$44/mo';
      }
      return '$39/mo';
    },
  },
};
